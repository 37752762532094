import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { useWindow } from "context/windowContext";
import Main from "components/Layout/main";
import Image from "components/UI/image";

import "styles/faq-page.scss";

const linkQuery = graphql`
query LinkQuery {
  allContentfulFooterUpdated {
    nodes {
      subscriptionIcons {
        alt
        image {
          title
          file {
            url
            contentType
          }
          gatsbyImageData(
            placeholder: NONE,
            formats: [AUTO, WEBP, AVIF],
          )
        }
      }
    }
  }
}
`

const FAQSinglePage = (props) => {
    const { pageContext } = props;

    const gtmData = {
        page: {
            title: "FAQ",
            type: "Static",
        },
    };

    return (
        <Main className="faq-wrapper" gtmData={gtmData}>
            <FaqSinglePageWrapper pageContext={pageContext}/>
        </Main>
    );
};

const FaqSinglePageWrapper = (props) => {
    const { pageContext } = props;

    const {
        name,
        authorAvatar,
        authorName,
        parentCategoryLink,
        parentCategoryName,
        publishDate,
        richText,
        subCategory,
        relatedLinks,
    } = pageContext;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: "numeric", month: "long", day: "numeric" };
        const formattedDate = date.toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const { document } = useWindow();
    const currentUrl = document?.location?.href;

    const linksData = useStaticQuery(linkQuery)
    const links = linksData.allContentfulFooterUpdated.nodes[0].subscriptionIcons

    return (
        <div className="faq">
            <div className="faq__breadcrumbs">
                <span>
                    <Link className="faq__breadcrumbs--link" to="/faq">
                        Wellis
                    </Link>
                </span>
                <span> / </span>
                <Link className="faq__breadcrumbs--link" to={`/faq${parentCategoryLink}`}>
                    <span>{parentCategoryName}</span>
                </Link>
                <span> / </span>
                <span>{subCategory}</span>
            </div>
            <div className="faq__single-article">
                <aside className="faq__single-article__aside">
                    <p className="faq__single-article__aside--title">
                        articles in this topic
                    </p>
                    {relatedLinks.map((link) => (
                        <Link key={link.id}
                              className={`${
                                  currentUrl.includes(link.href)
                                      ? "faq__single-article__aside--link--active"
                                      : "faq__single-article__aside--link"
                              }`}
                              to={`/faq${parentCategoryLink}${link.href}`}
                        >
                            {link.text}
                        </Link>
                    ))}
                </aside>
                <div className="faq__single-article__article">
                    <h3 className="faq__single-article__article--title">{name}</h3>
                    <div className="faq__single-article__article--info">
                        <Image image={authorAvatar} className="article-avatar"/>
                        <div className="article-text-wrapper">
                            <p>
                                Written by{" "}
                                <span className="article-text-wrapper__author">
                                    {authorName}
                                </span>
                            </p>
                            <p>
                                published on <span>{formatDate(publishDate)}</span>
                            </p>
                        </div>
                    </div>
                    <div className="faq__single-article__article--content">
                        {renderRichText(richText)}
                    </div>
                    <div className="faq__single-article__article--chat-hours">
                        <p className="text">
                            <span className="heading">Live Chat:</span>
                            {" "}
                            Click the "Help" bubble at the bottom left.
                        </p>
                        <p className="text">
                            <span className="heading">Hours:</span>
                            {" "}
                            7 days a week, 5AM to 8PM Pacific Time
                        </p>
                    </div>
                    <div className="faq__single-article__article--social">
                        {links.map((item, index) =>
                            <Link to={item.alt} key={index}>
                                <Image image={item.image}/>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQSinglePage;
